import React from 'react';
import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';
import translate from 'learn-common-i18n';

const SingleSignOutNotPossible = ({ t }) =>
  (
    <React.Fragment>
      <h2>
        {t('cannotlogout.headers.title')}
      </h2>

      <Divider primary thick className="mb-3" />

      <Notification warning message={t('cannotlogout.messages.cannotLogoutMessage')} title="" />
    </React.Fragment>
  );

export default translate()(SingleSignOutNotPossible);
