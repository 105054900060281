import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import Button from 'learn-common-button';
import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';
import withRouter from 'react-router-dom/es/withRouter';
import redirect from '../../shared/utils/redirect';

export const SelectProvider = (props) => {
  const {
    t,
    optForLocalPassword,
    kennisnetIdpUrl,
    tlnB2eIdpUrl
  } = props;

  return (
    <div>
      <h2>{t('select-provider.headers.title')}</h2>
      <Divider primary thick className="mb-3" />
      <p>{t('select-provider.descriptions.choose-method')}</p>
      <p>{t('select-provider.descriptions.kennisnet-method')}</p>
      <p>
        <Button
            fullWidth
            secondary
            text={t('select-provider.actions.use-kennisnet')}
            onClick={() => redirect(kennisnetIdpUrl)}
          />
      </p>
      <p>{t('select-provider.descriptions.tln-b2e-method')}</p>
      <p>
        <Button
          fullWidth
          secondary
          text={t('select-provider.actions.use-tln-b2e')}
          onClick={() => redirect(tlnB2eIdpUrl)}
        />
      </p>
      <p>{t('select-provider.descriptions.password-method')}</p>
      <Button
        fullWidth
        secondary
        text={t('select-provider.actions.use-password')}
        onClick={optForLocalPassword}
      />
    </div>
  );
}

SelectProvider.displayName = 'SelectProvider';

SelectProvider.propTypes = {
  optForLocalPassword: PropTypes.func.isRequired,
  kennisnetIdpUrl: PropTypes.string.isRequired,
  tlnB2eIdpUrl: PropTypes.string.isRequired,
};

export default compose(
  translate(),
  withRouter
)(SelectProvider);
  