import React from 'react';
import PropTypes from 'prop-types';

import AntiForgeryToken from 'learn-common-antiforgerytoken';
import Button from 'learn-common-button';
import translate from 'learn-common-i18n';

import LoginEmailAddress from './LoginEmailAddress';
import LoginPassword from './LoginPassword';

const LoginForm = ({
  antiForgery,
  emailAddress,
  isLoading,
  loginUrl,
  onContinueClick,
  onEmailAddressValidation,
  onLogin,
  onPasswordValidation,
  showPasswordInput,
  t
}) => (
  <form noValidate action={loginUrl} id="login" method="POST" onSubmit={onLogin}>
    <AntiForgeryToken token={antiForgery} />

    <LoginEmailAddress
      emailAddress={emailAddress}
      label={t('login.fields.emailAddress.label')}
      messages={{
        validateEmail: t('login.fields.emailAddress.validateEmail'),
        validateRequired: t('login.fields.emailAddress.validateRequired')
      }}
      onValidEmailAddress={onEmailAddressValidation}
    />

    {!showPasswordInput
      && (
      <Button
        fullWidth
        secondary
        data-purpose="nextButton"
        disabled={isLoading}
        loading={isLoading}
        text={t('login.actions.continue')}
        type="submit"
        onClick={onContinueClick}
      />
      )}

    {showPasswordInput
      && (
      <LoginPassword
        autoFocus
        label={t('login.fields.password.label')}
        messages={{
          validateRequired: t('login.fields.password.validateRequired')
        }}
        minLength={0}
        onValidPassword={onPasswordValidation}
      />
      )
    }

    {showPasswordInput
      && (
      <Button
        fullWidth
        secondary
        data-purpose="loginButton"
        disabled={isLoading}
        loading={isLoading}
        text={t('login.actions.login')}
        type="submit"
      />
      )}
  </form>
);

LoginForm.displayName = 'LoginForm';

LoginForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showPasswordInput: PropTypes.bool.isRequired,
  onContinueClick: PropTypes.func.isRequired,
  onEmailAddressValidation: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  onPasswordValidation: PropTypes.func.isRequired,
  antiForgery: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  emailAddress: PropTypes.string,
  loginUrl: PropTypes.string
};

LoginForm.defaultProps = {
  antiForgery: undefined,
  emailAddress: '',
  loginUrl: undefined
};

export default translate()(LoginForm);
