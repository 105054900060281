import 'babel-polyfill';
import 'whatwg-fetch';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import BrowserRouter from 'react-router-dom/es/BrowserRouter';
import Route from 'react-router-dom/es/Route';
import Switch from 'react-router-dom/es/Switch';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import { CookiesProvider } from 'react-cookie';
import qs from 'query-string';

import { preferredCultureDetector, TranslationContext } from 'learn-common-i18n';
import metrics, { MetricsRoute, newRelicMetricsHandler } from 'learn-common-metrics';

import resources from '../locales';

import App from './App';
import AccountConnectPage from './pages/account-connect/AccountConnectPage';
import Error404Page from './pages/error/Error404';
import Error403Page from './pages/error/Error403';
import Error500Page from './pages/error/Error500';
import ErrorPageWithParam from './pages/error/ErrorPageWithParam';
import LoginPageContainer from './pages/login/LoginPageContainer';
import LoggedOutPage from './pages/logout/LoggedOutPage';
import CannotLogoutPage from './pages/logout/CannotLogout';
import AuthorizePage from './pages/consent/AuthorizePage';
import ImpersonationPage from './pages/impersonation/ImpersonationPage';
import InformationRequiredPageContainer from './pages/information-required/InformationRequiredPageContainer';
import PasswordChangedPage from './pages/password-changed/PasswordChangedPage';
import ResetPasswordRequestPage from './pages/reset-password/ResetPasswordRequestPage';
import ResetPasswordResponsePage from './pages/reset-password/ResetPasswordResponsePage';
import ResetPasswordLandingPage from './pages/reset-password/ResetPasswordLandingPage';
import SetPasswordRequestPage from './pages/set-password/SetPasswordRequestPage';
import SetPasswordLandingPage from './pages/set-password/SetPasswordLandingPage';
import SelectSchoolPage from './pages/select-school/SelectSchoolPage';
import initializeStore, { initializeStateFromDefault } from './store';

import '../styles/site.scss';

metrics.use(newRelicMetricsHandler);

const serializedState = document.getElementById('initialState').innerHTML.trim();
const initialState = serializedState
  ? JSON.parse(serializedState)
  : {};

const { app: { brandName, preferredCultures = [] } = {} } = initialState;
const context = brandName || 'studystore';

const store = initializeStore({ initialState: initializeStateFromDefault(initialState) });

i18n
  .use(preferredCultureDetector)
  .init({
    detection: {
      preferredCultures
    },
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false
    },
    resources
  });

const renderErrorPage = (props) => {
  const { location: { search } } = props; // eslint-disable-line react/prop-types
  const { error } = qs.parse(search);
  return <ErrorPageWithParam {...props} error={error} />;
};

const component = (
  <Provider store={store}>
    <TranslationContext.Provider value={context}>
      <I18nextProvider i18n={i18n}>
        <CookiesProvider>
          <App>
            <BrowserRouter>
              <Switch>
                <MetricsRoute component={Error404Page} pageName="404" path="/404" />
                <MetricsRoute component={Error500Page} pageName="500" path="/500" />
                <MetricsRoute component={Error403Page} pageName="403" path="/403" />
                <MetricsRoute component={ResetPasswordLandingPage} path="/core/rp/" />
                <MetricsRoute component={InformationRequiredPageContainer} path="/core/information-required" />
                <MetricsRoute component={ResetPasswordResponsePage} path="/core/resetpassword/success" />
                <MetricsRoute component={ResetPasswordRequestPage} path="/core/resetpassword" />
                <MetricsRoute component={SetPasswordLandingPage} path="/core/sp/" />
                <MetricsRoute component={SetPasswordRequestPage} path="/core/setpassword" />
                <MetricsRoute component={LoginPageContainer} pageName="LoginPage" path="/core/login" />
                <MetricsRoute component={LoggedOutPage} path="/core/logout" />
                <MetricsRoute component={AuthorizePage} path="/core/connect/authorize" />
                <MetricsRoute component={AuthorizePage} path="/core/connect/consent" />
                <MetricsRoute component={SelectSchoolPage} path="/core/selectschool" />
                <MetricsRoute pageName="ErrorPage" path="/core/error" render={renderErrorPage} />
                <MetricsRoute component={ImpersonationPage} path="/impersonation/impersonate" />
                <MetricsRoute component={CannotLogoutPage} path="/CannotLogout" />
                <MetricsRoute component={AccountConnectPage} path="/magiclink/" />
                <MetricsRoute component={PasswordChangedPage} path="/passwordchanged" />
                <Route component={Error404Page} />
              </Switch>
            </BrowserRouter>
          </App>
        </CookiesProvider>
      </I18nextProvider>
    </TranslationContext.Provider>
  </Provider>
);

render(component, document.getElementById('react-root'));
