/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';

import Button from 'learn-common-button';

import { validateFormField } from '../../../shared/utils/forms';

class VerificationCode extends React.Component {
  debounceSetState = debounce(this.setState, 100);

  state = {
    errors: {},
    verificationCode: ''
  };

  handleVerificationCodeValidation = (target) => {
    const { onVerificationCodeValidation } = this.props;
    const { errors } = validateFormField(target, this.state);

    this.setState({ errors });

    const isValid = (target.validity.valid && !errors.verificationCode);
    onVerificationCodeValidation({ isValid, verificationCode: target.value });
  }

  handleVerificationCodeChange = ({ target }) => {
    const { validationPause } = this.state;
    if (validationPause) return;

    this.setState({ verificationCode: target.value.toUpperCase() },
      () => this.handleVerificationCodeValidation(target));
  }

  handleVerificationCodePaste = ({ clipboardData, target }) => {
    this.setState({
      verificationCode: clipboardData.getData('text')
        .substr(0, 5).trim().toUpperCase(),
      validationPause: true
    }, () => this.handleVerificationCodeValidation(target));

    this.debounceSetState({ validationPause: false });
  }

  handleEnterPressed = ({ target, key }) => {
    if (key === 'Enter') {
      this.handleVerificationCodeChange({ target });
    }
  }

  render() {
    const {
      autoFocus,
      className,
      isLoading,
      isValidVerificationCode,
      isVerificationCodeValidated,
      label,
      maxLength,
      messages: {
        validatePattern,
        validateMaxLength,
        validateMinLength,
        validateRequired
      },
      minLength,
      verifyButtonText,
      onVerifyVerificationCode
    } = this.props;

    const { verificationCode, errors } = this.state;

    return (
      <div className={`form-group input-verificationCode ${className}`}>
        <label htmlFor="verificationCode">
          {label}
        </label>
        <div className="input-group">
          <input
            required
            autoComplete="off"
            autoCorrect="off"
            autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            className="form-control"
            data-purpose="login.verificationCode"
            data-validate-maxlength={validateMaxLength}
            data-validate-minlength={validateMinLength}
            data-validate-pattern={validatePattern}
            data-validate-required={validateRequired}
            disabled={isLoading || (isValidVerificationCode && isVerificationCodeValidated)}
            id="verificationCode"
            maxLength={maxLength}
            minLength={minLength}
            name="verificationCode"
            pattern={`.{${minLength},${maxLength}}`}
            spellCheck="false"
            type="text"
            value={verificationCode}
            onChange={this.handleVerificationCodeChange}
            onKeyPress={this.handleEnterPressed}
            onPaste={this.handleVerificationCodePaste}
          />
          <div className="input-group-append">
            <Button
              secondary
              className="input-group-addon"
              data-purpose="verifyButton"
              disabled={isLoading || (isValidVerificationCode && isVerificationCodeValidated)}
              loading={isLoading}
              text={!(isValidVerificationCode && isVerificationCodeValidated) ? verifyButtonText : '✔'}
              type="submit"
              onClick={onVerifyVerificationCode}
            />
          </div>
        </div>

        {errors.verificationCode}
      </div>
    );
  }
}

VerificationCode.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isValidVerificationCode: PropTypes.bool.isRequired,
  isVerificationCodeValidated: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  messages: PropTypes.shape({
    validateRequired: PropTypes.string.isRequired,
    validateMaxLength: PropTypes.string,
    validateMinLength: PropTypes.string,
    validatePattern: PropTypes.string
  }).isRequired,
  verifyButtonText: PropTypes.string.isRequired,
  onVerificationCodeValidation: PropTypes.func.isRequired,
  onVerifyVerificationCode: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  minLength: PropTypes.number
};

VerificationCode.defaultProps = {
  autoFocus: false,
  className: 'mb-3',
  maxLength: 5,
  minLength: 5
};

export default VerificationCode;
