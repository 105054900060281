/* eslint-disable import/prefer-default-export */
import { createActions } from 'redux-actions';

const {
  app: {
    bypassSelector
  }
} = createActions({
  app: {
    bypassSelector: undefined
  }
});

export { bypassSelector };
