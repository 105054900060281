import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';

export const AccountConnectPage = ({ t, succeeded = false }) => (
  <React.Fragment>
    <h2>
      {succeeded ? t('account-connect.headers.title') :  t('account-connect.headers.titleFailed')}
    </h2>
    <Divider primary thick className="mb-3" />

    <p>
      {succeeded ? t('account-connect.messages.succeeded') : t('account-connect.messages.failed')}
    </p>
    <p>
      {!succeeded && t('account-connect.reason.linkMightBeExpired')}
    </p>
  </React.Fragment>
);

AccountConnectPage.propTypes = {
  succeeded: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => {
  const {
    app: { succeeded }
  } = state;

  return {
    succeeded
  };
};

export default compose(
  connect(mapStateToProps),
  translate(),
)(AccountConnectPage);
