import React from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { compose, withHandlers, withProps } from 'recompose';

import translate from 'learn-common-i18n';

import { ensureIdentityModelPresent, requireSignInId } from '../../shared/utils/hoc';
import renderForError from '../../shared/utils/renderForError';
import redirect from '../../shared/utils/redirect';
import * as app from '../../state/app';

import SelectSchool from './components/SelectSchool';
import SchoolLogin from './components/SchoolLogin';

import selectorConfiguration from './selector-config';

export class SelectSchoolPage extends React.Component {
  state = {
    fetchSchools: {
      loading: true,
      error: false,
      data: undefined
    },
    selectedSchool: undefined
  };

  componentDidMount() {
    const { fetchSchools, selectedSchoolId } = this.props;

    return fetchSchools()
      .then((data) => {
        this.setState({ fetchSchools: { loading: false, error: false, data } }, () => {
          if (selectedSchoolId) {
            const selectedSchool = data.find(x => x.id === selectedSchoolId);
            this.setState({ selectedSchool });
          }
        });
      })
      .catch(() => this.setState({ fetchSchools: { loading: false, error: true, data: undefined } }));
  }

  handleChangeSchool = () => this.setState({ selectedSchool: undefined });

  handleSelectSchool = selectedSchool => this.setState({ selectedSchool });

  handleSchoolLogin = () => {
    const { selectedSchool } = this.state;
    const { onSchoolLogin } = this.props;

    if (selectedSchool) {
      onSchoolLogin(selectedSchool);
    }
  }

  render() {
    const { loginInternal, requiresExternalLogin, t } = this.props;
    const { fetchSchools, selectedSchool } = this.state;

    return (
      <div>
        {!selectedSchool
          && (
          <SelectSchool
            fetchSchools={fetchSchools}
            t={t}
            onLoginInternal={loginInternal}
            onSchoolSelected={this.handleSelectSchool}
          />)
        }

        {selectedSchool
          && (
            <SchoolLogin
              requiresExternalLogin={requiresExternalLogin(selectedSchool)}
              schoolName={selectedSchool.name}
              t={t}
              onChangeSchool={this.handleChangeSchool}
              onSchoolLogin={this.handleSchoolLogin}
            />
          )}
      </div>
    );
  }
}

SelectSchoolPage.propTypes = {
  fetchSchools: PropTypes.func.isRequired,
  loginInternal: PropTypes.func.isRequired,
  requiresExternalLogin: PropTypes.func.isRequired,
  onSchoolLogin: PropTypes.func.isRequired,
  selectedSchoolId: PropTypes.string
};

SelectSchoolPage.defaultProps = {
  selectedSchoolId: undefined
};

const mapStateToProps = (state) => {
  const {
    app: {
      brandName,
      isValidSelector,
      selectorType,
      showSelector,
      signInId
    }
  } = state;

  return {
    brandName,
    kennisnetIdpUrl: app.getKennisnetIdpUrl(state),
    isValidSelector,
    loginUrl: app.getLoginUrl(state),
    selectorType,
    showSelector,
    signInId
  };
};

const mapDispatchToProps = {
  bypassSelector: app.bypassSelector
};

export const selectedSchoolCookie = selectorType => ({
  name: `selectedSchool-${selectorType}`,
  options: {
    maxAge: 60 * 60 * 24 * 365.25
  }
});

export const onSchoolLogin = ({
  cookies,
  buildRedirectUrl,
  loginInternal,
  requiresExternalLogin,
  selectorType
}) => (selectedSchool) => {
  const { id } = selectedSchool;
  const { name, options } = selectedSchoolCookie(selectorType);

  cookies.set(name, id, options);

  if (requiresExternalLogin(selectedSchool)) {
    const redirectUrl = buildRedirectUrl(selectedSchool);
    redirect(redirectUrl);
  } else {
    loginInternal();
  }
};

export const loginWithLearnId = ({ bypassSelector, history, loginUrl }) => () => {
  bypassSelector();
  history.push(loginUrl);
};

const ensureValidSelectorType = renderForError(
  ({ isValidSelector, showSelector }) => showSelector && !isValidSelector,
  ({ t }) => ({ errorMessage: t('selectSchool.messages.invalidSelector') }),
  { showBackButton: false }
);

const selectedSchoolIdFromCookie = withProps(({ cookies, selectorType }) => ({
  selectedSchoolId: cookies.get(selectedSchoolCookie(selectorType).name)
}));

export default compose(
  withCookies,
  connect(mapStateToProps, mapDispatchToProps),
  translate(),
  ensureValidSelectorType,
  ensureIdentityModelPresent,
  requireSignInId,
  selectedSchoolIdFromCookie,
  withHandlers({ loginWithLearnId }),
  withProps(selectorConfiguration),
  withHandlers({ onSchoolLogin })
)(SelectSchoolPage);
