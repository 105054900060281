import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import withRouter from 'react-router-dom/es/withRouter';

import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';

// import { ensureIdentityModelPresent } from '../../shared/utils/hoc';
import renderForError from '../../shared/utils/renderForError';

import InformationRequiredPage from './InformationRequiredPage';

export const InformationRequiredPageContainer = (props) => {
  const {
    t
  } = props;

  return (
    <div>
      <h2>
        {t('information-required.headers.title')}
      </h2>

      <Divider primary thick className="mb-3" />

      <p>
        {t('information-required.headers.subtitle')}
      </p>

      <InformationRequiredPage {...props} />
    </div>
  );
};

InformationRequiredPageContainer.displayName = 'InformationRequiredPageContainer';

InformationRequiredPageContainer.propTypes = {
  continuationUrl: PropTypes.string.isRequired,
  // hasIdentityModel: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  // antiForgery: PropTypes.shape({
  //   name: PropTypes.string.isRequired,
  //   value: PropTypes.string.isRequired
  // }),
  errorMessage: PropTypes.string
};

InformationRequiredPageContainer.defaultProps = {
  // antiForgery: undefined,
  errorMessage: ''
};

const mapStateToProps = (state) => {
  const {
    app: { selectorType, showSelector, signInId },
    identity: {
      // antiForgery,
      continuationUrl,
      errorMessage
    }
  } = state;

  return {
    // antiForgery,
    continuationUrl,
    errorMessage,
    selectorType,
    signInId,
    showSelector
  };
};

export default compose(
  connect(mapStateToProps),
  renderForError(({ errorMessage }) => errorMessage),
  // ensureIdentityModelPresent,
  translate(),
  withRouter
)(InformationRequiredPageContainer);
