import React from 'react';
import PropTypes from 'prop-types';

import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';
import Notification from 'learn-common-notification';

export const ErrorPage = ({ errorMessage, showBackButton, t }) => (
  <React.Fragment>
    <h2>
      {t('error.headers.title')}
    </h2>

    <Divider primary thick className="mb-3" />

    <Notification error message={errorMessage} />

    {showBackButton && (
      <React.Fragment>
        <Divider gray className="mt-3" />

        <div className="text-center mb-2">
          <a href="/core/login/redirect">
            {t('error.actions.back')}
          </a>
        </div>
      </React.Fragment>
    )}
  </React.Fragment>
);

ErrorPage.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool
};

ErrorPage.defaultProps = {
  showBackButton: true
};

export default translate()(ErrorPage);
