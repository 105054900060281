import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Button from 'learn-common-button';
import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';
import Notification from 'learn-common-notification';

import { requireProps } from '../../shared/utils/hoc';
import renderForError from '../../shared/utils/renderForError';

export const ImpersonationPage = ({ returnUrl, t, token, username }) => (
  <form action="/impersonation/impersonate" method="post">
    <input name="tokenSecret" type="hidden" value={token} />

    <h2>
      {t('impersonate.headers.title')}
    </h2>

    <Divider primary thick className="mb-3" />

    <div className="mb-2">
      <Trans i18nKey="impersonate.messages.impersonationDetails">
        {'Je wordt nu ingelogd als '}
        <strong>
          {username}
        </strong>
        {' en doorgestuurd naar '}
        <strong>
          {returnUrl}
        </strong>
        {'.'}
      </Trans>
    </div>

    <div className="mb-3">
      <Notification
        warning
        title=""
      >
        <Trans i18nKey="impersonate.messages.privateBrowser">
          {'Controleer of je in een '}
          <strong>
            {'private-browservenster'}
          </strong>
          {' zit, anders kan je je huidige sessie kwijtraken!'}
        </Trans>
      </Notification>
    </div>

    <div className="form-group mb-3">
      <label htmlFor="description">
        {t('impersonate.fields.description.label')}
      </label>
      <textarea className="form-control" name="description" rows={3} />
    </div>

    <Button
      fullWidth
      secondary
      data-purpose="impersonateButton"
      text={t('impersonate.actions.continue')}
      type="submit"
    />
  </form>
);

ImpersonationPage.propTypes = {
  returnUrl: PropTypes.string,
  token: PropTypes.string,
  username: PropTypes.string
};

ImpersonationPage.defaultProps = {
  returnUrl: '',
  token: '',
  username: ''
};

const mapStateToProps = ({ app: { token, returnUrl, username } }) => ({ token, returnUrl, username });

export default compose(
  translate(),
  connect(mapStateToProps),
  requireProps('impersonate.messages.invalidToken', 'token', 'returnUrl', 'username'),
  renderForError(({ errorMessage }) => errorMessage)
)(ImpersonationPage);
