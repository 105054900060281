import React from 'react';
import PropTypes from 'prop-types';

// import AntiForgeryToken from 'learn-common-antiforgerytoken';
import Button from 'learn-common-button';
import translate from 'learn-common-i18n';

import LinkEmailAddress from './LinkEmailAddress';
import VerificationCode from './VerificationCode';

const InformationRequiredForm = ({
  // antiForgery,
  continuationCode,
  emailAddress,
  isLoading,
  onContinueClick,
  onContinueToClient,
  onEmailAddressValidation,
  onVerificationCodeValidation,
  showVerificationCodeInput,
  isValidVerificationCode,
  isVerificationCodeValidated,
  t
}) => (
  <React.Fragment>
    {/* <AntiForgeryToken token={antiForgery} /> */}

    <LinkEmailAddress
      emailAddress={emailAddress}
      label={t('information-required.fields.emailAddress.label')}
      messages={{
        validateEmail: t('information-required.fields.emailAddress.validateEmail'),
        validateRequired: t('information-required.fields.emailAddress.validateRequired')
      }}
      onValidEmailAddress={onEmailAddressValidation}
    />

    {!showVerificationCodeInput && !(isValidVerificationCode && isVerificationCodeValidated)
      && (
      <Button
        fullWidth
        secondary
        data-purpose="nextButton"
        disabled={isLoading}
        loading={isLoading}
        text={t('information-required.actions.continue')}
        type="submit"
        onClick={onContinueClick}
      />
      )}

    {(showVerificationCodeInput || (isValidVerificationCode && isVerificationCodeValidated))
      && (
      <React.Fragment>
        <VerificationCode
          autoFocus
          isLoading={isLoading}
          isValidVerificationCode={isValidVerificationCode}
          isVerificationCodeValidated={isVerificationCodeValidated}
          label={t('information-required.fields.verificationCode.label')}
          messages={{
            validateMaxLength: t('information-required.fields.verificationCode.validateMaxLength'),
            validateMinLength: t('information-required.fields.verificationCode.validateMinLength'),
            validateRequired: t('information-required.fields.verificationCode.validateRequired')
          }}
          verifyButtonText={t('information-required.actions.verify')}
          onVerificationCodeValidation={onVerificationCodeValidation}
          onVerifyVerificationCode={onVerificationCodeValidation}
        />
      </React.Fragment>
      )}

    {isValidVerificationCode && isVerificationCodeValidated && !showVerificationCodeInput
      && (
        <React.Fragment>
          <input
            name="code"
            type="hidden"
            value={continuationCode}
          />
          <Button
            fullWidth
            secondary
            data-purpose="onContinueToClient"
            disabled={isLoading}
            loading={isLoading}
            text={t('information-required.actions.onContinueToClient')}
            // type="submit"
            onClick={onContinueToClient}
          />
        </React.Fragment>
      )}
  </React.Fragment>
);

InformationRequiredForm.displayName = 'InformationRequiredForm';

InformationRequiredForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onContinueClick: PropTypes.func.isRequired,
  onContinueToClient: PropTypes.func.isRequired,
  onEmailAddressValidation: PropTypes.func.isRequired,
  onVerificationCodeValidation: PropTypes.func.isRequired,
  // antiForgery: PropTypes.shape({
  //   name: PropTypes.string.isRequired,
  //   value: PropTypes.string.isRequired
  // }),
  continuationCode: PropTypes.string,
  emailAddress: PropTypes.string,
  isValidVerificationCode: PropTypes.bool,
  isVerificationCodeValidated: PropTypes.bool,
  showVerificationCodeInput: PropTypes.bool
};

InformationRequiredForm.defaultProps = {
  // antiForgery: undefined,
  continuationCode: undefined,
  emailAddress: '',
  isValidVerificationCode: false,
  isVerificationCodeValidated: false,
  showVerificationCodeInput: false
};

export default translate()(InformationRequiredForm);
