import React from 'react';
import PropTypes from 'prop-types';

import Button from 'learn-common-button';
import Divider from 'learn-common-divider';

const SchoolLogin = ({ onChangeSchool, onSchoolLogin, requiresExternalLogin, schoolName, t }) => {
  const translationKeySuffix = requiresExternalLogin ? 'loginSchoolAccount' : 'loginLearnID';

  return (
    <div>
      <h2>
        {t('selectSchool.headers.login')}
      </h2>

      <Divider primary thick className="mb-3" />

      <div className="form-group mb-3">
        <label>
          {t('selectSchool.fields.selectedSchool.label')}
        </label>
        <div data-purpose="selected-school-name">
          <strong>
            {schoolName}
          </strong>
        </div>
      </div>

      <p>
        {t(`selectSchool.descriptions.${translationKeySuffix}`)}
      </p>

      <Button
        fullWidth
        secondary
        text={t(`selectSchool.actions.${translationKeySuffix}`)}
        onClick={onSchoolLogin}
      />

      <div className="text-center mt-3">
        <Button
          link
          text={t('selectSchool.actions.changeSchool')}
          onClick={onChangeSchool}
        />
      </div>
    </div>
  );
};

SchoolLogin.propTypes = {
  requiresExternalLogin: PropTypes.bool.isRequired,
  schoolName: PropTypes.string.isRequired,
  onChangeSchool: PropTypes.func.isRequired,
  onSchoolLogin: PropTypes.func.isRequired
};

export default SchoolLogin;
