import React from 'react';
import PropTypes from 'prop-types';

import styles from './NoWrap.scss';

const NoWrap = ({ children }) => (
  <span className={styles.noWrap}>
    {children}
  </span>
);

NoWrap.propTypes = {
  children: PropTypes.node.isRequired
};

export default NoWrap;
