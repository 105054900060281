import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Link from 'react-router-dom/es/Link';
import withRouter from 'react-router-dom/es/withRouter';

import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';
import translate from 'learn-common-i18n';

import NoWrap from '../../components/NoWrap';
import checkLoginId from '../../shared/services/check-login-id';
import { requestResetPassword } from '../../shared/services/request-set-password';
import { requireSignInId } from '../../shared/utils/hoc';
import { getLoginUrl, getResetPasswordSuccessUrl } from '../../state/app';

import RequestResetForm from './components/RequestResetForm';

export class ResetPasswordRequestPage extends React.Component {
  state = {
    emailAddress: '',
    countAttempts: 0,
    isLoading: false,
    isValidEmailAddress: false,
    isValidGoogleRecaptcha: false,
    resetHasFailed: false,
    isUnknownEmailAddress: undefined
  };

  handleEmailAddressValidation = ({ isValid, emailAddress }) => this.setState({ isValidEmailAddress: isValid, emailAddress });

  handleGoogleRecaptchaValidation = value => this.setState({ isValidGoogleRecaptcha: (value !== null) });

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      emailAddress,
      isValidEmailAddress,
      isValidGoogleRecaptcha,
      countAttempts
    } = this.state;

    const { metrics } = this.props;

    const showGoogleRecaptcha = countAttempts >= 3;

    if (isValidEmailAddress && (!showGoogleRecaptcha || (showGoogleRecaptcha && isValidGoogleRecaptcha))) {
      this.setState({ isLoading: true, countAttempts: (countAttempts + 1) });

      return checkLoginId(emailAddress)
        .then(({ exists }) => {
          if (exists) {
            metrics.registerEvent('passwordResetRequested');
            this.sendResetRequest(emailAddress);
          } else {
            metrics.registerEvent('passwordResetUnknownEmail');
          }
          this.setState({ isLoading: false, isUnknownEmailAddress: !exists });
        })
        .catch(() => this.setState({ isLoading: false, isUnknownEmailAddress: undefined }));
    }

    this.setState({ isLoading: false, isUnknownEmailAddress: undefined });

    return Promise.resolve();
  }

  sendResetRequest = (emailAddress) => {
    const {
      history,
      metrics,
      resetPasswordSuccessUrl,
      signInId
    } = this.props;

    this.setState({ isLoading: true });

    return requestResetPassword(emailAddress, signInId)
      .then(() => {
        metrics.registerEvent('passwordResetRequestSuccess');
        history.push(resetPasswordSuccessUrl);
      })
      .catch(() => {
        metrics.registerEvent('passwordResetRequestFailed', { emailAddress });
        this.setState({ isLoading: false, resetHasFailed: true });
      });
  }

  render() {
    const {
      emailAddress,
      countAttempts,
      isLoading,
      isUnknownEmailAddress,
      resetHasFailed
    } = this.state;

    const {
      errorMessage,
      loginUrl,
      t
    } = this.props;

    return (
      <div>
        <h2>
          {t('reset.headers.request')}
        </h2>

        <Divider primary thick className="mb-3" />

        <p>
          <Trans i18nKey="reset.descriptions.request">
            <NoWrap>
              {'e-mail address'}
            </NoWrap>
          </Trans>
        </p>

        {errorMessage
          && (
          <Notification
            error
            message={t(`reset.messages.${errorMessage}`)}
          />
          )
        }

        {resetHasFailed
          && (
          <Notification
            error
            message={t('reset.messages.resetHasFailed')}
          />
          )
        }

        {isUnknownEmailAddress
          && (
          <Notification
            warning
          >
            <Trans i18nKey="reset.messages.unknownEmailAddress">
              <NoWrap>
                {'e-mail address'}
              </NoWrap>
            </Trans>
          </Notification>
          )
        }

        <RequestResetForm
          {...this.props}
          emailAddress={emailAddress}
          isLoading={isLoading}
          showGoogleRecaptcha={countAttempts >= 3}
          onEmailAddressValidation={this.handleEmailAddressValidation}
          onSubmit={this.handleSubmit}
          onValidGoogleRecaptcha={this.handleGoogleRecaptchaValidation}
        />

        <Divider gray className="mt-3" />

        <div className="text-center">
          <Link to={loginUrl}>
            {t('reset.actions.backToLogin')}
          </Link>
        </div>
      </div>
    );
  }
}

ResetPasswordRequestPage.propTypes = {
  history: PropTypes.shape({}).isRequired,
  loginUrl: PropTypes.string.isRequired,
  resetPasswordSuccessUrl: PropTypes.string.isRequired,
  signInId: PropTypes.string.isRequired,
  errorMessage: PropTypes.string
};

ResetPasswordRequestPage.defaultProps = {
  errorMessage: ''
};

const mapStateToProps = (state) => {
  const { app: { signInId } } = state;

  return {
    loginUrl: getLoginUrl(state),
    resetPasswordSuccessUrl: getResetPasswordSuccessUrl(state),
    signInId
  };
};

export default compose(
  connect(mapStateToProps),
  translate(),
  requireSignInId,
  withRouter
)(ResetPasswordRequestPage);
