import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import Countdown from 'learn-common-countdown';
import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';
import translate from 'learn-common-i18n';

import redirect from '../../../shared/utils/redirect';

export class SingleSignOutPossible extends React.Component {
  componentDidMount() {
    const { autoRedirectDelay, autoRedirect } = this.props;

    if (autoRedirect && autoRedirectDelay === 0) {
      this.handleRedirectToClient();
    }
  }

  handleRedirectToClient = () => {
    const { redirectUrl } = this.props;
    redirect(redirectUrl);
  }

  renderLogoutComponent = () => {
    const { autoRedirect, autoRedirectDelay, redirectUrl, t } = this.props;
    const delay = autoRedirectDelay || 0;

    return (
      <React.Fragment>
        <h2>
          {t('logout.headers.title')}
        </h2>

        <Divider primary thick className="mb-3" />

        <Notification success title={t('logout.messages.logoutSuccessTitle')}>
          <p>
            {t('logout.messages.logoutSuccessDescription')}
          </p>

          {autoRedirect && (delay > 0) && (
            <div className="mt-2" data-purpose="countdown">
              <Trans i18nKey="logout.messages.automaticRedirect" t={t}>
                <Countdown countStart={delay} onComplete={this.handleRedirectToClient} />
              </Trans>
            </div>
          )}

          {redirectUrl
            && (
            <React.Fragment>
              <Divider gray />
              <div className="text-center">
                <a href={redirectUrl}>
                  {t('logout.actions.backToSite')}
                </a>
              </div>
            </React.Fragment>
            )
          }
        </Notification>
      </React.Fragment>
    );
  }

  render() {
    const { autoRedirect, autoRedirectDelay, redirectUrl } = this.props;
    const showComponent = redirectUrl === null || (autoRedirect && autoRedirectDelay > 0) || !autoRedirect;

    return (
      <React.Fragment>
        {showComponent && this.renderLogoutComponent()}
      </React.Fragment>
    );
  }
}

SingleSignOutPossible.propTypes = {
  autoRedirect: PropTypes.bool,
  autoRedirectDelay: PropTypes.number,
  redirectUrl: PropTypes.string
};

SingleSignOutPossible.defaultProps = {
  autoRedirect: false,
  autoRedirectDelay: 0,
  redirectUrl: ''
};

export default translate()(SingleSignOutPossible);
