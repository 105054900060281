import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducer';

export const defaultState = {
  app: {},
  identity: {}
};

export const initializeStateFromDefault = (state = {}) => Object.keys(defaultState).reduce((acc, key) => {
  if (state[key]) acc[key] = state[key];
  return acc;
}, {});

export const getDevToolsExtensionMiddleware = () => {
  const hasDevTools = process.env.NODE_ENV === 'development'
    && typeof window === 'object'
    && typeof window.devToolsExtension !== 'undefined';

  return hasDevTools ? window.devToolsExtension() : f => f;
};

export default function initializeStore({ initialState } = {}) {
  const middleware = [];
  const enhancers = compose(applyMiddleware(...middleware), getDevToolsExtensionMiddleware());

  const store = createStore(rootReducer, initialState, enhancers);

  return store;
}
