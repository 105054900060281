import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import Link from 'react-router-dom/es/Link';
import { compose } from 'recompose';

import Notification from 'learn-common-notification';
import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';

import NoWrap from '../../components/NoWrap';
import { requireSignInId } from '../../shared/utils/hoc';
import { getLoginUrl } from '../../state/app';

export const ResetPasswordResponsePage = ({ loginUrl, t }) => (
  <div>
    <h2>
      {t('reset.headers.request')}
    </h2>

    <Divider primary thick className="mb-3" />

    <Notification
      success
      title={t('reset.messages.resetHasSucceededTitle')}
    >
      <Trans i18nKey="reset.messages.resetHasSucceeded">
        <NoWrap>
          {'e-mail address'}
        </NoWrap>
      </Trans>
    </Notification>

    <Divider gray className="mt-3" />

    <div className="text-center">
      <Link to={loginUrl}>
        {t('reset.actions.backToLogin')}
      </Link>
    </div>
  </div>
);

ResetPasswordResponsePage.propTypes = {
  loginUrl: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const { app: { signInId } } = state;

  return {
    loginUrl: getLoginUrl(state),
    signInId
  };
};

export default compose(
  connect(mapStateToProps),
  translate(),
  requireSignInId
)(ResetPasswordResponsePage);
