/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { connect } from 'react-redux';
import withRouter from 'react-router-dom/es/withRouter';
import { branch, compose, renderComponent } from 'recompose';

import renderForError from './renderForError';

import redirect from './redirect';

export const requireProps = (errorKey, ...requiredProps) => renderForError(
  props => !requiredProps.map(x => props[x]).every(Boolean),
  ({ t }) => ({ errorMessage: t(errorKey) })
);

export const requireSignInId = requireProps('error.messages.noLoginSession', 'signInId');

export const RedirectToLogin = ({ signInId }) => {
  redirect(`/core/login?signin=${signInId}`);
  return (
    <div>
      {'Redirecting...'}
    </div>
  );
};

const PushComponent = ({ history, to }) => {
  history.push(to);
  return null;
};

export const RouterPush = withRouter(PushComponent);

export const conditionallyRender = (condition, createComponent, onRender) => branch(
  condition,
  renderComponent((props) => {
    if (onRender) {
      onRender(props);
    }

    return createComponent(props);
  })
);

export const conditionallyNavigateTo = (condition, to, onNavigate) => conditionallyRender(
  condition,
  props => <RouterPush to={to(props)} />,
  onNavigate
);

export const ensureIdentityModelPresent = compose(
  connect(({ app: { signInId }, identity }) => ({
    hasIdentityModel: Object.keys(identity).length > 0,
    signInId
  })),
  conditionallyRender(
    ({ hasIdentityModel }) => !hasIdentityModel,
    ({ signInId }) => <RedirectToLogin signInId={signInId} />
  )
);
