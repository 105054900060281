import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import Select, { createFilter } from 'react-select';

import Button from 'learn-common-button';
import Notification from 'learn-common-notification';
import Divider from 'learn-common-divider';
import Spinner from 'learn-common-spinner';

import './SelectSchool.scss';

class SelectSchool extends React.Component {
  state = {
    selectedSchool: undefined
  }

  componentDidMount() {
    document.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        this.handleSchoolSelected(event);
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keypress');
  }

  handleSchoolChange = selectedSchool => this.setState({ selectedSchool });

  handleSchoolSelected = (event) => {
    event.preventDefault();

    const { onSchoolSelected } = this.props;
    const { selectedSchool } = this.state;

    if (selectedSchool) {
      onSchoolSelected(selectedSchool);
    }
  }

  renderSchoolSelector = () => {
    const {
      fetchSchools: {
        loading,
        data: organizations
      },
      t
    } = this.props;

    const noSchoolsAvailable = !loading && (!organizations || organizations.length === 0);

    return (
      <form onSubmit={this.handleSchoolSelected}>
        {!noSchoolsAvailable && (
          <div className="form-group mb-3">
            <label>
              {t('selectSchool.fields.school.label')}
            </label>

            <div>
              {!loading && (
                <Select
                  blurInputOnSelect
                  isSearchable
                  classNamePrefix="select-school"
                  filterOption={createFilter({
                    ignoreCase: true,
                    stringify: option => option.label
                  })}
                  getOptionLabel={opt => opt.name}
                  getOptionValue={opt => opt}
                  noOptionsMessage={() => t('selectSchool.messages.filterNoSchoolsFound')}
                  options={organizations}
                  placeholder={t('selectSchool.fields.school.placeholder')}
                  onChange={this.handleSchoolChange}
                />
              )}

              {loading && <Spinner dark />}
            </div>
          </div>
        )}

        {noSchoolsAvailable
          && (
            <Notification
              error
              message={t('selectSchool.messages.noOrganizationsFound')}
            />
          )}

        <Button
          fullWidth
          secondary
          disabled={loading || noSchoolsAvailable}
          text={t('selectSchool.actions.continue')}
          type="submit"
        />
      </form>
    );
  }

  render() {
    const { fetchSchools: { error }, onLoginInternal, t } = this.props;

    return (
      <div>
        <h2>
          {t('selectSchool.headers.title')}
        </h2>

        <Divider primary thick className="mb-3" />

        <p>
          {t('selectSchool.descriptions.landing')}
        </p>

        {error
          && (
            <Notification
              error
              message={t('selectSchool.messages.organizationsLoadFailed')}
            />
          )}

        {!error && this.renderSchoolSelector()}

        <Divider gray className="mt-3" />

        <div className="text-center">
          <Trans i18nKey="selectSchool.actions.escape">
            <Button link data-purpose="escape-link" onClick={onLoginInternal}>
              {'click here'}
            </Button>
          </Trans>
        </div>
      </div>
    );
  }
}

SelectSchool.propTypes = {
  fetchSchools: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  onLoginInternal: PropTypes.func.isRequired,
  onSchoolSelected: PropTypes.func.isRequired
};

export default SelectSchool;
