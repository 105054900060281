import React from 'react';

import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';
import translate from 'learn-common-i18n';

export const CannotLogout = ({ t }) => (
  <React.Fragment>
    <h2>
      {t('cannotlogout.headers.title')}
    </h2>

    <Divider primary thick className="mb-3" />

    <Notification warning message={t('cannotlogout.messages.cannotLogoutMessage')} />

    <Divider gray className="mt-3" />

    <div className="text-center mb-2">
      <a href="/core/login/redirect">
        {t('cannotlogout.actions.redirectLink')}
      </a>
    </div>
  </React.Fragment>
);

export default translate()(CannotLogout);
