import React from 'react';

import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';

export const Error403 = () => (
  <React.Fragment>
    <h2>
      {'Geen toegang'}
    </h2>

    <Divider primary thick className="mb-3" />

    <Notification error message="Je hebt geen toegang tot de opgevraagde pagina." />

    <Divider gray className="mt-3" />

    <div className="text-center mb-2">
      <a href="/core/login/redirect">
        {'Ga naar de inlogpagina'}
      </a>
    </div>
  </React.Fragment>
);

export default Error403;
