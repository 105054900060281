import { createSelector } from 'reselect';

const getUrlByType = (links, type) => ((links || []).find(x => x.type === type) || {}).href;

export const getSignInId = ({ app: { signInId } }) => signInId;

const getAdditionalLinks = ({ app: { additionalLinks } }) => additionalLinks;
const getExternalProviders = ({ identity: { externalProviders } }) => externalProviders;

export const getLoginUrl = createSelector([getSignInId], signInId => `/core/login?signin=${signInId}`);
export const getResetPasswordUrl = createSelector([getSignInId], signInId => `/core/resetpassword?signin=${signInId}`);
export const getResetPasswordSuccessUrl = createSelector([getSignInId], signInId => `/core/resetpassword/success?signin=${signInId}`);
export const getSetPasswordUrl = createSelector([getSignInId], signInId => `/core/setpassword?signin=${signInId}`);

export const getRegistrationUrl = createSelector([getAdditionalLinks], links => getUrlByType(links, 'registration'));
export const getKennisnetIdpUrl = createSelector([getExternalProviders], links => getUrlByType(links, 'kennisnet'));
export const getTlnB2eIdpUrl = createSelector([getExternalProviders], links => getUrlByType(links, 'tln-b2e'));
export const getExternalLoginUrlGetter = createSelector(
    [getExternalProviders], links => (id, params) => {
        const url = getUrlByType(links, id);
        const query = Object.entries(params || {}).map(([key, value]) => `&${key}=${value}`).join('');
        return !url ? url : url + query;
    }
);
