import fetch from '../utils/fetch';

const setPasswordAction = setPasswordUrl => (token, newPassword) => fetch(setPasswordUrl, {
  method: 'post',
  body: JSON.stringify({ token, newPassword })
})
  .then(({ isSuccess, failureReason, returnUrl }) => {
    if (!isSuccess) {
      throw new Error(failureReason);
    }

    return returnUrl;
  });

export const setPassword = setPasswordAction('/core/setpassword/set');
export const resetPassword = setPasswordAction('/core/resetpassword/reset');
