import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import Notification from 'learn-common-notification';

import NoWrap from '../../components/NoWrap';
import checkLoginId from '../../shared/services/check-login-id';

import LoginForm from './components/LoginForm';
import redirect from '../../shared/utils/redirect';

export class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    if (props.username) {
      // When we get back a username from Identity Server (i.e. in cases of failed auth),
      // assume the e-mail address to be valid and show the password input field.
      this.state = this.setEmailAddressFromInitialState(props.username);
    }
  }

  state = {
    emailAddress: '',
    showPassword: false,
    isLoading: false,
    isValidEmailAddress: false,
    isValidPassword: false,
    isUnknownEmailAddress: false,
    userRequiresNewPassword: false,
    validatedEmailAddresses: []
  };

  setEmailAddressFromInitialState = username => ({
    ...this.state,
    emailAddress: username,
    isValidEmailAddress: true,
    isUnknownEmailAddress: false,
    showPassword: true
  });

  validateLoginId = (emailAddress) => {
    const { history, metrics, setPasswordUrl, hybridLogin, getExternalLoginUrl } = this.props;
    const { validatedEmailAddresses } = this.state;

    const validatedEmailAddress = validatedEmailAddresses.find(x => x.emailAddress === emailAddress);

    /**
     * Caching of the validated emailaddresses
     */
    if (validatedEmailAddress && validatedEmailAddress.exists) {
      return this.setState({
        isUnknownEmailAddress: false,
        showPassword: true,
        userRequiresNewPassword: validatedEmailAddress.userRequiresNewPassword
      });
    }

    this.setState({ isLoading: true });
    return checkLoginId(emailAddress)
      .then(({ exists, userRequiresNewPassword, preferredIdp, idpParameters }) => {
        const externalLoginUrl = getExternalLoginUrl(preferredIdp, idpParameters);
        if (hybridLogin && !!preferredIdp && !!externalLoginUrl) {
          metrics.registerEvent('userAutofederated', { emailAddress });
          const fullUrl = externalLoginUrl + '&login_hint=' + encodeURIComponent(emailAddress);
          redirect(fullUrl);
          return;
        }

        if (exists && userRequiresNewPassword) {
          metrics.registerEvent('userRequiresNewPassword', { emailAddress });
          history.push(setPasswordUrl, { emailAddress });
          return;
        }

        this.setState(prevState => ({
          isLoading: false,
          isUnknownEmailAddress: !exists,
          showPassword: exists,
          validatedEmailAddresses: [{ emailAddress, exists, userRequiresNewPassword }, ...prevState.validatedEmailAddresses],
          userRequiresNewPassword
        }));
      })
      .catch(() => this.setState({ isLoading: false, isUnknownEmailAddress: undefined, showPassword: false }));
  }

  handleContinueClick = () => {
    const { emailAddress, isValidEmailAddress } = this.state;

    if (!emailAddress || !isValidEmailAddress) return;
    this.validateLoginId(emailAddress);
  };

  handleEmailAddressValidation = ({ isValid, emailAddress }) => {
    if (isValid) {
      this.setState({ isValidEmailAddress: true });
      this.validateLoginId(emailAddress);
    } else {
      this.setState({
        isValidEmailAddress: false,
        showPassword: false,
        emailAddress
      });
    }
  };

  handlePasswordValidation = ({ isValid }) => this.setState({ isValidPassword: isValid });

  handleSubmit = (e) => {
    e.preventDefault();

    const { target } = e;
    const { metrics } = this.props;
    const { isValidEmailAddress, isValidPassword } = this.state;

    if (!target.checkValidity || (target.checkValidity() && isValidEmailAddress && isValidPassword)) {
      metrics.registerEvent('submitLogin');

      target.submit();
      this.setState({ isLoading: true });
    }
    return false;
  };

  render() {
    const {
      emailAddress,
      isLoading,
      isUnknownEmailAddress,
      showPassword
    } = this.state;

    const { errorMessage, t } = this.props;

    return (
      <React.Fragment>
        {isUnknownEmailAddress
          && (
            <Notification
              warning
            >
              <Trans i18nKey="login.messages.unknownEmailAddress">
                <NoWrap>
                  {'e-mail address'}
                </NoWrap>
              </Trans>
            </Notification>
          )
        }

        {errorMessage
          && (
            <Notification
              error
              message={t(`login.messages.${errorMessage}`)}
            />
          )
        }

        <LoginForm
          {...this.props}
          emailAddress={emailAddress}
          isLoading={isLoading}
          isUnknownEmailAddress={isUnknownEmailAddress}
          showPasswordInput={showPassword}
          onContinueClick={this.handleContinueClick}
          onEmailAddressValidation={this.handleEmailAddressValidation}
          onLogin={this.handleSubmit}
          onPasswordValidation={this.handlePasswordValidation}
        />
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  loginUrl: PropTypes.string.isRequired,
  setPasswordUrl: PropTypes.string.isRequired,
  signInId: PropTypes.string.isRequired,
  antiForgery: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  errorMessage: PropTypes.string,
  username: PropTypes.string
};

LoginPage.defaultProps = {
  antiForgery: undefined,
  errorMessage: '',
  username: ''
};

export default LoginPage;
