import fetch from '../utils/fetch';
import redirect from '../utils/redirect';

export const provideExistingEmailaddress = code => fetch('/core/information-provided', {
  method: 'post',
  body: JSON.stringify([{ type: 'code', value: code }])
}).then(({ IsSuccess, RedirectUrl }) => {
  if (IsSuccess) redirect(RedirectUrl);
  else throw Error('invalid code');
});

export const provideNewEmailaddress = emailAddress => fetch('/core/information-provided', {
  method: 'post',
  body: JSON.stringify([{ type: 'email', value: emailAddress }])
});

export const requestVerificationCode = username => fetch('/core/verificationcode/request', {
  method: 'post',
  body: JSON.stringify({ username })
});

export const verifyVerificationCode = code => fetch('/core/verificationcode/verify', {
  method: 'post',
  body: JSON.stringify({ code })
});
