import convertNullsToUndefined from './nulls-to-undefined';

export default (endpoint, options = {}) => fetch(endpoint, {
  ...options,
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(options.headers || {})
  }
})
  .then((result) => {
    if (!result.ok) {
      throw result;
    }

    if (result.status === 204) {
      return {};
    }

    return result
      .text()
      .then(text => (text ? JSON.parse(text) : {}));
  })
  .then(json => convertNullsToUndefined(json))
  .catch((error) => {
    return error
      .text()
      .then((text) => {
        throw (text ? convertNullsToUndefined(JSON.parse(text)) : {});
      });
  });
