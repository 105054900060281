import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Footer from 'learn-common-footer';
import Header from 'learn-common-header';
import translate from 'learn-common-i18n';
import correctTopSpacer from 'learn-common-spacer';

import styles from './App.scss';

const App = ({ children, app: { brandName, brandUrl }, t }) => (
  <div className="container-fluid p-0 bg--shade">
    <div className="d-flex flex-row align-content-start flex-wrap">
      <Header brandName={brandName} brandUrl={brandUrl} headerTitle={t('header.title')} />
      <main className="d-flex col-12 col-lg-6 justify-content-center align-self-start h-100 cancel-all-padding-margin">
        <div className={`${styles.content} p-3 fadeInRight`}>
          {children}
        </div>
      </main>
    </div>
    <Footer footerDescription={t('footer.description')} />
  </div>
);

App.propTypes = {
  app: PropTypes.shape({
    brandName: PropTypes.string,
    brandUrl: PropTypes.string,
    signin: PropTypes.string
  }),
  children: PropTypes.node
};

App.defaultProps = {
  app: {},
  children: undefined
};

const mapStateToProps = ({ app }) => ({ app });

export default compose(
  connect(mapStateToProps),
  translate(),
  correctTopSpacer
)(App);
