import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';
import Notification from 'learn-common-notification';

import NoWrap from '../../../components/NoWrap';

const MaxLoginAttemptsReached = ({ loginUrl, t }) => (
  <div>
    <h2>
      {t('login.headers.loginFailed')}
    </h2>

    <Divider primary thick className="mb-3" />

    <Notification
      success
      title={t('login.headers.resetEmailSent')}
    >
      <Trans i18nKey="login.messages.resetEmailSent">
        <NoWrap>
          {'e-mail address'}
        </NoWrap>
      </Trans>
    </Notification>

    <Divider gray className="mt-3" />

    <div className="text-center">
      <a href={loginUrl}>
        {t('reset.actions.backToLogin')}
      </a>
    </div>
  </div>
);

MaxLoginAttemptsReached.propTypes = {
  loginUrl: PropTypes.string.isRequired
};

export default translate()(MaxLoginAttemptsReached);
