import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Link from 'react-router-dom/es/Link';
import withRouter from 'react-router-dom/es/withRouter';

import Button from 'learn-common-button';
import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';

import { conditionallyNavigateTo, conditionallyRender, ensureIdentityModelPresent } from '../../shared/utils/hoc';
import renderForError from '../../shared/utils/renderForError';
import redirect from '../../shared/utils/redirect';
import { getRegistrationUrl, getResetPasswordUrl, getSetPasswordUrl, getTlnB2eIdpUrl, getKennisnetIdpUrl, getExternalLoginUrlGetter } from '../../state/app';

import LoginPage from './LoginPage';
import MaxLoginAttemptsReached from './components/MaxLoginAttemptsReached';
import SelectProvider from './SelectProvider';

export const LoginPageContainer = (props) => {
  const {
    registrationUrl,
    resetPasswordUrl,
    t,
    hybridLogin,
    kennisnetIdpUrl,
    tlnB2eIdpUrl
  } = props;
  const [state, setState] = React.useState({ doLocalLogin: false });
  const showPasswordLogin = !hybridLogin || state.doLocalLogin;
  const setLocalLogin = value => () => setState({...state, doLocalLogin: value});

  if (!showPasswordLogin) {
    const optForLocalPassword = setLocalLogin(true);
    const selectProviderProps = { kennisnetIdpUrl, tlnB2eIdpUrl, optForLocalPassword };
    return <SelectProvider {...selectProviderProps}/>;
  }

  const returnToSelection = compose(setLocalLogin(false), e => e.preventDefault());
  return (
    <div>
      <h2>
        {t('login.headers.title')}
      </h2>

      <Divider primary thick className="mb-3" />

        <LoginPage {...props} />
        <p></p>
      {hybridLogin &&
        <div className="text-center"><a onClick={returnToSelection} href="">{t('select-provider.actions.different-method')}</a></div>}

      <Divider gray className="mt-3" />

      {registrationUrl
        && (
        <div className="text-center mb-2">
          <span className="mr-1">
            {t('login.messages.noAccount')}
          </span>
          <a data-purpose="signupLink" href={registrationUrl}>
            {t('login.actions.register')}
          </a>
        </div>
        )
      }

      <div className="text-center">
        <Link to={resetPasswordUrl}>
          {t('login.actions.forgotPassword')}
        </Link>
      </div>
    </div>
  );
};

LoginPageContainer.displayName = 'LoginPageContainer';

LoginPageContainer.propTypes = {
  hasIdentityModel: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
  loginUrl: PropTypes.string.isRequired,
  maxLoginAttemptsReached: PropTypes.bool.isRequired,
  registrationUrl: PropTypes.string.isRequired,
  resetPasswordUrl: PropTypes.string.isRequired,
  setPasswordUrl: PropTypes.string.isRequired,
  antiForgery: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  errorMessage: PropTypes.string,
  username: PropTypes.string,
  hybridLogin: PropTypes.bool,
  kennisnetIdpUrl: PropTypes.string,
  tlnB2eIdpUrl: PropTypes.string,
  getExternalLoginUrl: PropTypes.func
};

LoginPageContainer.defaultProps = {
  antiForgery: undefined,
  errorMessage: '',
  username: '',
  hybridLogin: false
};

const mapStateToProps = (state) => {
  const {
    app: { selectorType, showSelector, signInId, hybridLogin },
    identity: {
      antiForgery,
      errorMessage,
      loginUrl,
      username
    }
  } = state;

  return {
    antiForgery,
    errorMessage,
    loginUrl,
    maxLoginAttemptsReached: errorMessage === 'MaxFailedLoginCountReached',
    registrationUrl: getRegistrationUrl(state),
    resetPasswordUrl: getResetPasswordUrl(state),
    selectorType,
    setPasswordUrl: getSetPasswordUrl(state),
    signInId,
    showSelector,
    username,
    hybridLogin,
    kennisnetIdpUrl: getKennisnetIdpUrl(state),
    tlnB2eIdpUrl: getTlnB2eIdpUrl(state),
    getExternalLoginUrl: getExternalLoginUrlGetter(state)
  };
};

const checkMaximumLoginAttempts = conditionallyRender(
  ({ maxLoginAttemptsReached }) => maxLoginAttemptsReached,
  ({ loginUrl }) => <MaxLoginAttemptsReached loginUrl={loginUrl} />,
  ({ metrics }) => metrics.registerEvent('maxLoginAttemptsReached')
);

const shouldShowSchoolSelector = conditionallyNavigateTo(
  ({ showSelector, errorMessage }) => !errorMessage && showSelector,
  ({ signInId }) => `/core/selectschool?signin=${signInId}`,
  ({ metrics }) => metrics.registerEvent('showSchoolSelector')
);

export default compose(
  connect(mapStateToProps),
  checkMaximumLoginAttempts,
  renderForError(({ loginUrl, errorMessage }) => !loginUrl && errorMessage),
  shouldShowSchoolSelector,
  ensureIdentityModelPresent,
  translate(),
  withRouter
)(LoginPageContainer);
