import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import translate from 'learn-common-i18n';
import redirect from '../../shared/utils/redirect';

import renderForError from '../../shared/utils/renderForError';

import SignoutIFrames from './components/SignOutIFrames';
import NotAuthenticated from './components/NotAuthenticated';
import SingleSignOutNotPossible from './components/SingleSignOutNotPossible';
import SingleSignOutPossible from './components/SingleSignOutPossible';

export class LoggedOutPage extends React.Component {
  renderSignOutComponent = () => {
    const { singleSignOutPossible, redirectUrl } = this.props;

    if(redirectUrl){
      return (
        <React.Fragment>
          {singleSignOutPossible && <SingleSignOutPossible {...this.props} />}
          {!singleSignOutPossible && <SingleSignOutNotPossible />}
        </React.Fragment>
      );
    }

    return <NotAuthenticated redirectUrl={redirectUrl} />;
  }

  render() {
    const { iFrameUrls } = this.props;

    return (
      <React.Fragment>
        {this.renderSignOutComponent()}
        <SignoutIFrames iFrameUrls={iFrameUrls} />
      </React.Fragment>
    );
  }
}

LoggedOutPage.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  singleSignOutPossible: PropTypes.bool.isRequired,
  autoRedirect: PropTypes.bool,
  autoRedirectDelay: PropTypes.number,
  iFrameUrls: PropTypes.arrayOf(PropTypes.string),
  redirectUrl: PropTypes.string
};

LoggedOutPage.defaultProps = {
  autoRedirect: false,
  autoRedirectDelay: 0,
  iFrameUrls: [],
  redirectUrl: ''
};

const mapStateToProps = ({
  app: {
    isAuthenticated,
    singleSignOutPossible
  },
  identity: {
    autoRedirect,
    autoRedirectDelay,
    errorMessage,
    iFrameUrls,
    redirectUrl
  }
}) => ({
  autoRedirect,
  autoRedirectDelay,
  errorMessage,
  iFrameUrls,
  isAuthenticated,
  redirectUrl,
  singleSignOutPossible
});

export default compose(
  connect(mapStateToProps),
  renderForError(({ redirectUrl, errorMessage }) => !redirectUrl && errorMessage),
  translate()
)(LoggedOutPage);
