import { combineReducers } from 'redux';
import app from './state/app';

const identity = state => ({ ...state });

const reducers = {
  app,
  identity
};

export default combineReducers({ ...reducers });
