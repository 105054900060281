import isPlainObject from 'lodash-es/isPlainObject';

const convertNullsToUndefined = (input) => {
  if (!input) return undefined;
  if (!isPlainObject(input)) return input;

  return Object.keys(input).reduce((acc, key) => {
    const value = input[key];

    if (value && isPlainObject(value)) {
      acc[key] = convertNullsToUndefined(value);
    } else {
      acc[key] = value === null ? undefined : value;
    }

    return acc;
  }, {});
};

export default convertNullsToUndefined;
