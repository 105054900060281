import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Link from 'react-router-dom/es/Link';

import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';
import translate from 'learn-common-i18n';

import { requireProps, requireSignInId } from '../../shared/utils/hoc';
import redirectTo from '../../shared/utils/redirect';
import { getLoginUrl } from '../../state/app';

import { setPassword } from '../../shared/services/set-password';
import ResetLandingForm from '../reset-password/components/ResetLandingForm';

export class SetPasswordLandingPage extends React.Component {
  state = {
    isLoading: false,
    isValidPassword: false,
    password: '',
    saveFailed: false
  };

  handlePasswordValidation = ({ isValid, password }) => this.setState({ isValidPassword: isValid, password });

  handleSubmit = (e) => {
    e.preventDefault();

    const { metrics, redirect, token } = this.props;
    const { isValidPassword, password } = this.state;

    this.setState({ isLoading: true, saveFailed: false });

    if (isValidPassword) {
      return setPassword(token, password)
        .then((returnUrl) => {
          metrics.registerEvent('passwordSetSuccess');
          redirect(returnUrl);
        })
        .catch(() => {
          metrics.registerEvent('passwordSetFailed');
          this.setState({ saveFailed: true });
        });
    }

    this.setState({ isLoading: false });
    return Promise.resolve();
  }

  render() {
    const { isLoading, saveFailed } = this.state;
    const { errorMessage, loginUrl, t } = this.props;

    return (
      <div>
        <h2>
          {t('setpassword.headers.landing')}
        </h2>

        <Divider primary thick className="mb-3" />

        {errorMessage
          && (
          <Notification
            error
            message={t(`setpassword.messages.${errorMessage}`)}
          />
          )
        }

        {saveFailed
          && (
          <Notification
            error
            message={t('setpassword.messages.setPasswordFailed')}
          />
          )
        }

        <p>
          {t('setpassword.descriptions.landing')}
        </p>

        <ResetLandingForm
          {...this.props}
          isLoading={isLoading}
          onPasswordValidation={this.handlePasswordValidation}
          onSubmit={this.handleSubmit}
        />

        <Divider gray className="mt-3" />

        <div className="text-center">
          <Link to={loginUrl}>
            {t('setpassword.actions.backToLogin')}
          </Link>
        </div>
      </div>
    );
  }
}

SetPasswordLandingPage.propTypes = {
  loginUrl: PropTypes.string.isRequired,
  redirect: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  signInId: PropTypes.string,
  token: PropTypes.string
};

SetPasswordLandingPage.defaultProps = {
  errorMessage: '',
  token: '',
  signInId: ''
};

const mapStateToProps = (state) => {
  const { app: { token, signInId } } = state;

  return {
    loginUrl: getLoginUrl(state),
    redirect: redirectTo,
    token,
    signInId
  };
};

export default compose(
  translate(),
  connect(mapStateToProps),
  requireProps('setpassword.messages.invalidToken', 'token'),
  requireSignInId
)(SetPasswordLandingPage);
