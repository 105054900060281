import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash-es/debounce';
import { validateFormField } from '../../../shared/utils/forms';

class LoginEmailAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: props.emailAddress,
      validationPause: false,
      errors: {}
    };
    this.setErrors = debounce(
      (errors) => {
        this.setState({ errors });
      },
      500
    );
    this.debounceSetState = debounce(this.setState, 100);
    this.debounceEmailValidation = debounce(this.handleEmailAddressValidation, 800);
  }

  componentWillUnmount() {
    this.setErrors.cancel();
    this.debounceSetState.cancel();
    this.debounceEmailValidation.cancel();
  }

  handleEmailAddressValidation = (target) => {
    const { onValidEmailAddress } = this.props;
    const { errors } = validateFormField(target, this.state);

    if (errors.username) this.setErrors(errors, 500);
    else this.setErrors(errors, 0);

    const isValid = target.validity.valid && !errors.username;
    onValidEmailAddress({ isValid, emailAddress: target.value });
  };

  handleEmailAddressChange = ({ target }) => {
    const { validationPause } = this.state;
    if (validationPause) return;

    this.setState({ emailAddress: target.value }, () =>
      this.debounceEmailValidation(target));
  };

  handleEmailAddressPaste = ({ clipboardData, target }) => {
    this.setState({ emailAddress: clipboardData.getData('text'), validationPause: true }, () =>
      this.handleEmailAddressValidation(target));

    this.debounceSetState({ validationPause: false });
  };

  handleEnterPressed = ({ target, key }) => {
    if (key === 'Enter') {
      this.setState({ emailAddress: target.value }, () =>
        this.handleEmailAddressValidation(target));
    }
  };

  render() {
    const {
      label,
      messages: { validateEmail, validateRequired }
    } = this.props;
    const { emailAddress, errors } = this.state;

    return (
      <div className="form-group mb-3">
        <label htmlFor="email">
          {label}
        </label>
        <input
          autoFocus // eslint-disable-line jsx-a11y/no-autofocus
          required
          autoCorrect="off"
          className="form-control"
          data-purpose="login.emailAddress"
          data-validate-email={validateEmail}
          data-validate-required={validateRequired}
          id="username"
          maxLength={256}
          name="username"
          spellCheck="false"
          type="email"
          value={emailAddress}
          onChange={this.handleEmailAddressChange}
          onKeyPress={this.handleEnterPressed}
          onPaste={this.handleEmailAddressPaste}
        />
        {errors.username}
      </div>
    );
  }
}

LoginEmailAddress.propTypes = {
  label: PropTypes.string.isRequired,
  messages: PropTypes.shape({
    validateEmail: PropTypes.string.isRequired,
    validateRequired: PropTypes.string.isRequired
  }).isRequired,
  onValidEmailAddress: PropTypes.func.isRequired,
  emailAddress: PropTypes.string
};

LoginEmailAddress.defaultProps = {
  emailAddress: ''
};

export default LoginEmailAddress;
