import React from 'react';
import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';

import renderForError from '../../shared/utils/renderForError';

import AuthorizePageAutologin from './AuthorizePageAutoLogin';
import AuthorizePageConsent from './AuthorizePageConsent';

const mapStateToProps = ({ identity, identity: { consentUrl, errorMessage } }) => ({
  consentUrl,
  errorMessage,
  identity
});

const AuthorizePage = () => <div />;

export default compose(
  connect(mapStateToProps),
  renderForError(({ consentUrl, errorMessage }) => !consentUrl && errorMessage),
  branch(
    (props) => {
      const { identity } = props;
      return identity.identityScopes && identity.identityScopes.length > 0;
    },
    renderComponent(({ identity }) => <AuthorizePageConsent {...identity} />),
    renderComponent(props => <AuthorizePageAutologin {...props} />)
  )
)(AuthorizePage);
