import React from 'react';
import { compose, withProps } from 'recompose';
import PropTypes from 'prop-types';
import translate from 'learn-common-i18n';

import ErrorPage from './ErrorPage';

const errorConfig = {
  'external-login-failed': {
    errorKey: 'externalLoginFailed',
    showBackButton: false
  }
};

export const ErrorPageWithParam = ({ errorKey, showBackButton, t }) => (
  <ErrorPage
    errorMessage={t(`error.messages.${errorKey}`)}
    showBackButton={showBackButton}
  />
);

ErrorPageWithParam.propTypes = {
  errorKey: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired
};

export default compose(
  withProps(({ error }) => ({ ...errorConfig[error] })),
  translate()
)(ErrorPageWithParam);
