import { getOrganizations, getVoSchools } from '../../shared/services/organizations';
import redirect from '../../shared/utils/redirect';

export default ({ brandName, kennisnetIdpUrl, loginWithLearnId, selectorType }) => {
  const config = {
    mbo: {
      fetchSchools: () => getOrganizations(brandName),
      requiresExternalLogin: ({ requiresExternalLogin }) => requiresExternalLogin,
      buildRedirectUrl: ({ brin }) => `${kennisnetIdpUrl}&brin=${brin}`,
      loginInternal: loginWithLearnId
    },
    vo: {
      fetchSchools: getVoSchools,
      requiresExternalLogin: ({ brin }) => brin !== 'ITLN',
      buildRedirectUrl: ({ brin }) => `${kennisnetIdpUrl}&brin=${brin}`,
      loginInternal: () => redirect(`${kennisnetIdpUrl}&brin=ITLN`)
    }
  };

  return config[selectorType];
};
