import React from 'react';
import PropTypes from 'prop-types';
import { GoogleRecaptcha } from 'learn-common-googlerecaptcha';

import Button from 'learn-common-button';
import translate from 'learn-common-i18n';

import LoginEmailAddress from '../../login/components/LoginEmailAddress';

const RequestResetForm = ({
  emailAddress,
  isLoading,
  showGoogleRecaptcha,
  onEmailAddressValidation,
  onSubmit,
  onValidGoogleRecaptcha,
  t
}) => (
  <form noValidate id="reset">
    <LoginEmailAddress
      emailAddress={emailAddress}
      label={t('login.fields.emailAddress.label')}
      messages={{
        validateEmail: t('login.fields.emailAddress.validateEmail'),
        validateRequired: t('login.fields.emailAddress.validateRequired')
      }}
      onValidEmailAddress={onEmailAddressValidation}
    />

    {showGoogleRecaptcha
      && <GoogleRecaptcha onValidGoogleRecaptcha={onValidGoogleRecaptcha} />
    }

    <Button
      fullWidth
      secondary
      loading={isLoading}
      text={t('reset.actions.resetPassword')}
      onClick={onSubmit}
    />
  </form>
);

RequestResetForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onEmailAddressValidation: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValidGoogleRecaptcha: PropTypes.func.isRequired,
  emailAddress: PropTypes.string,
  showGoogleRecaptcha: PropTypes.bool
};

RequestResetForm.defaultProps = {
  emailAddress: '',
  showGoogleRecaptcha: false
};

export default translate()(RequestResetForm);
