import React from 'react';
import Notification from 'learn-common-notification';
import { Trans } from 'react-i18next';
import withRouter from 'react-router-dom/es/withRouter';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import translate from 'learn-common-i18n';
import Divider from 'learn-common-divider';

export class PasswordChangedPage extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <h2>
          {t('passwordChanged.headers.title')}
        </h2>
        <Divider primary thick className="mb-3" />
        
        <Notification success title={null}>
          <Trans i18nKey="passwordChanged.descriptions.landing"></Trans>
        </Notification>

      </div>
    );
  }
}

const mapStateToProps = (_state) => ({ });

export default compose(
  translate(),
  connect(mapStateToProps),
  withRouter
)(PasswordChangedPage);