import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';

export class AuthorizePageAutoLogin extends React.Component {
  componentDidMount() {
    this.form.submit();
  }

  setFormRef = (form) => {
    this.form = form;
  }

  render() {
    const { responseFormFields, responseFormUri, t } = this.props;

    return (
      <div>
        <h2>
          {t('login.headers.title')}
        </h2>

        <Divider primary thick className="mb-3" />

        <div>
          {t('login.messages.loggingIn')}
        </div>

        <form action={responseFormUri} method="POST" ref={this.setFormRef}>
          <div dangerouslySetInnerHTML={{ __html: responseFormFields }} /* eslint-disable-line react/no-danger */ />
        </form>
      </div>
    );
  }
}

AuthorizePageAutoLogin.propTypes = {
  responseFormFields: PropTypes.string.isRequired,
  responseFormUri: PropTypes.string.isRequired
};

const mapStateToProps = ({ identity: { responseFormFields, responseFormUri } }) => ({
  responseFormFields,
  responseFormUri
});

export default connect(mapStateToProps)(translate()(AuthorizePageAutoLogin));
