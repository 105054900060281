import React from 'react';
import PropTypes from 'prop-types';

import AntiForgeryToken from 'learn-common-antiforgerytoken';
import Button from 'learn-common-button';
import Divider from 'learn-common-divider';
import translate from 'learn-common-i18n';

import ConsentScope from './components/ConsentScope';

export class AuthorizePageConsent extends React.Component {
  renderContentScope = ({ name, ...rest }) => {
    const { t } = this.props;

    return (
      <ConsentScope
        key={name}
        mandatoryText={t('consent.fields.mandatory')}
        name={name}
        {...rest}
      />
    );
  }

  renderIdentityScopes = () => {
    const { identityScopes, t } = this.props;

    return (
      <React.Fragment>
        <h4 className="pb-2">
          {t('consent.headers.identityScopes')}
        </h4>

        {identityScopes.map(this.renderContentScope)}
      </React.Fragment>
    );
  };

  renderResourceScopes = () => {
    const { resourceScopes, t } = this.props;

    return (
      <React.Fragment>
        <h4 className="py-2">
          {t('consent.headers.resourceScopes')}
        </h4>

        {resourceScopes.map(this.renderContentScope)}
      </React.Fragment>
    );
  }

  render() {
    const {
      loginWithDifferentAccountUrl,
      consentUrl,
      antiForgery,
      clientName,
      allowRememberConsent,
      rememberConsent,
      identityScopes,
      currentUser,
      logoutUrl,
      resourceScopes,
      t
    } = this.props;

    return (
      <div>
        <h2>
          {t('consent.headers.title')}
        </h2>

        <Divider primary thick className="mb-3" />

        <form noValidate action={consentUrl} method="POST" ref={this.formRef}>
          <AntiForgeryToken token={antiForgery} />

          <p>
            {t('consent.description', { clientName, currentUser })}
            {' '}
            {loginWithDifferentAccountUrl && (
              <a href={loginWithDifferentAccountUrl}>
                {t('consent.actions.notme')}
              </a>
            )}
          </p>

          {identityScopes.length > 0 && this.renderIdentityScopes()}

          {resourceScopes.length > 0 && this.renderResourceScopes()}

          <div className="row my-3">
            <div className="col-12 col-lg-6">
              <Button
                fullWidth
                primary
                name="button"
                text={t('consent.actions.yesContinue')}
                type="submit"
                value="yes"
              />
              {allowRememberConsent === true
                && (
                <div className="form-check mt-2">
                  <label className="form-check-label">
                    <input className="form-check-input" name="RememberConsent" type="checkbox" value={rememberConsent} />
                    {t('consent.fields.rememberConsent')}
                  </label>
                </div>
                )
              }
            </div>
            <div className="col-12 col-lg-6">
              <Button
                fullWidth
                name="button"
                text={t('consent.actions.noCancel')}
                type="submit"
                value="no"
              />
            </div>
          </div>

          <Divider gray />
          <div className="text-center">
            <a className="btn" href={logoutUrl}>
              {t('consent.actions.logout')}
            </a>
          </div>
        </form>
      </div>
    );
  }
}

AuthorizePageConsent.propTypes = {
  allowRememberConsent: PropTypes.bool,
  antiForgery: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }),
  clientName: PropTypes.string,
  consentUrl: PropTypes.string,
  currentUser: PropTypes.string,
  identityScopes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    description: PropTypes.string,
    emphasize: PropTypes.bool,
    required: PropTypes.bool,
    selected: PropTypes.bool
  })),
  loginWithDifferentAccountUrl: PropTypes.string,
  logoutUrl: PropTypes.string,
  rememberConsent: PropTypes.bool,
  resourceScopes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    description: PropTypes.string,
    emphasize: PropTypes.bool,
    required: PropTypes.bool,
    selected: PropTypes.bool
  }))
};

AuthorizePageConsent.defaultProps = {
  antiForgery: undefined,
  loginWithDifferentAccountUrl: '',
  consentUrl: '',
  clientName: '',
  allowRememberConsent: true,
  rememberConsent: false,
  identityScopes: [],
  resourceScopes: [],
  currentUser: '',
  logoutUrl: ''
};

export default translate()(AuthorizePageConsent);
