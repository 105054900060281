import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Link from 'react-router-dom/es/Link';
import withRouter from 'react-router-dom/es/withRouter';

import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';
import translate from 'learn-common-i18n';

import { requestSetPassword } from '../../shared/services/request-set-password';
import redirectTo from '../../shared/utils/redirect';
import { requireSignInId } from '../../shared/utils/hoc';
import { getLoginUrl } from '../../state/app';

export class SetPasswordRequestPage extends React.Component {
  constructor(props) {
    super(props);

    const { location: { state: { emailAddress } = {} } = {} } = props;

    if (emailAddress) {
      this.state = this.setEmailAddressFromInitialState(emailAddress);
    }
  }

  state = {
    emailAddress: '',
    resetHasFailed: false
  };

  componentDidMount() {
    const { loginUrl, metrics, redirect } = this.props;
    const { emailAddress } = this.state;

    if (emailAddress) {
      metrics.registerEvent('passwordSetRequested');
      this.sendSetPasswordRequest(emailAddress);
    } else {
      metrics.registerEvent('passwordSetNoEmail');
      redirect(loginUrl);
    }
  }

  setEmailAddressFromInitialState = emailAddress => ({
    ...this.state,
    emailAddress
  });

  sendSetPasswordRequest = (emailAddress) => {
    const { metrics, signInId } = this.props;

    this.setState({ isLoading: true });

    requestSetPassword(emailAddress, signInId)
      .then(() => {
        metrics.registerEvent('passwordSetRequestSuccess');
        this.setState({ isLoading: false, setRequestHasFailed: false });
      })
      .catch(() => {
        metrics.registerEvent('passwordSetRequestFailed', { emailAddress });
        this.setState({ isLoading: false, setRequestHasFailed: true });
      });
  }

  render() {
    const {
      emailAddress,
      setRequestHasFailed
    } = this.state;

    const {
      loginUrl,
      t
    } = this.props;

    if (!emailAddress) return null;

    return (
      <div>
        <h2>
          {t('setpassword.headers.request')}
        </h2>

        <Divider primary thick className="mb-3" />

        {!setRequestHasFailed
          && (
          <Notification
            success
            message={t('setpassword.descriptions.request')}
            title={t('setpassword.messages.newPassword')}
          />
          )
        }

        {setRequestHasFailed
          && (
          <Notification
            error
            message={t('setpassword.messages.setRequestHasFailed')}
          />
          )
        }

        <div className="form-group mb-3">
          <label htmlFor="email">
            {t('login.fields.emailAddress.label')}
          </label>
          <div>
            {emailAddress}
          </div>
        </div>

        <Divider gray className="mt-3" />

        <div className="text-center">
          <Link to={loginUrl}>
            {t('setpassword.actions.backToLogin')}
          </Link>
        </div>
      </div>
    );
  }
}

SetPasswordRequestPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      emailAddress: PropTypes.string
    })
  }).isRequired,
  loginUrl: PropTypes.string.isRequired,
  redirect: PropTypes.func.isRequired,
  signInId: PropTypes.string.isRequired
};

const mapStateToProps = (state) => {
  const { app: { signInId } } = state;

  return {
    loginUrl: getLoginUrl(state),
    redirect: redirectTo,
    signInId
  };
};

export default compose(
  connect(mapStateToProps),
  translate(),
  requireSignInId,
  withRouter
)(SetPasswordRequestPage);
