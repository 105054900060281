/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { branch, renderComponent } from 'recompose';

import ErrorPage from '../../pages/error/ErrorPage';

const defaultErrorProps = {
  errorMessage: undefined,
  errorMessageProp: 'errorMessage'
};

const getErrorMessage = (props, { errorMessage, errorMessageProp } = {}) => (errorMessage || props[errorMessageProp]);

const renderForError = (hasErrorsFunc, configureErrorMessage = () => defaultErrorProps, errorPageProps = {}) => branch(
  props => hasErrorsFunc(props),
  renderComponent(props => (
    <ErrorPage
      errorMessage={getErrorMessage(props, configureErrorMessage(props))}
      {...errorPageProps}
    />))
);

export const renderErrorWhenPresent = () => renderForError(({ errorMessage }) => errorMessage);

export default renderForError;
