import React from 'react';
import PropTypes from 'prop-types';
import styles from './SignoutIFrames.scss';

const SignoutFrames = ({ iFrameUrls }) => {
  if (!iFrameUrls) return <React.Fragment />;

  return iFrameUrls.map((url, i) => (
    <iframe
      className={styles.signout}
      key={`signout-${i}`} // eslint-disable-line react/no-array-index-key
      src={url}
      title={`iframe-${i}`}
    />
  ));
};

SignoutFrames.propTypes = {
  iFrameUrls: PropTypes.arrayOf(PropTypes.string)
};

export default SignoutFrames;
