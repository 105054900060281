/* eslint-disable jsx-a11y/click-events-have-key-events */

import PropTypes from 'prop-types';
import React from 'react';
import imageVisibility from '../../../../img/visibility.svg';
import imageVisibilityOff from '../../../../img/visibility_off.svg';
import { validateFormField } from '../../../shared/utils/forms';

class LoginPassword extends React.Component {
  state = {
    errors: {},
    password: '',
    showPassword: false
  };

  handlePasswordValidation = (target) => {
    const { onValidPassword } = this.props;
    const { errors } = validateFormField(target, this.state);

    this.setState({ errors });

    const isValid = (target.validity.valid && !errors.password);
    onValidPassword({ isValid, password: target.value });
  }

  handlePasswordChange = ({ target }) =>
    this.setState({ password: target.value },
      () => this.handlePasswordValidation(target));

  handleTogglePasswordVisible = () =>
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));

  render() {
    const {
      autoFocus,
      className,
      label,
      messages: {
        validatePattern,
        validateRequired
      },
      minLength
    } = this.props;

    const { showPassword, password, errors } = this.state;

    return (
      <div className={`form-group input-password ${className}`}>
        <label htmlFor="password">
          {label}
        </label>
        <div className="input-group">
          <input
            required
            autoCorrect="off"
            autoFocus={autoFocus} // eslint-disable-line jsx-a11y/no-autofocus
            className="form-control"
            data-purpose="login.password"
            data-validate-pattern={validatePattern}
            data-validate-required={validateRequired}
            id="password"
            maxLength={64}
            minLength={minLength}
            name="password"
            pattern={`.{${minLength},64}`}
            spellCheck="false"
            type={showPassword ? 'text' : 'password'}
            value={password}
            onChange={this.handlePasswordChange}
            onPaste={this.handlePasswordChange}
          />
          <div className="input-group-append">
            <div className="input-group-text" onClick={this.handleTogglePasswordVisible}>
              {' '}
              <img alt="toggle visibility" src={showPassword ? imageVisibilityOff : imageVisibility} />
            </div>
          </div>
        </div>
        {errors.password}
      </div>
    );
  }
}

LoginPassword.propTypes = {
  label: PropTypes.string.isRequired,
  messages: PropTypes.shape({
    validateRequired: PropTypes.string.isRequired,
    validatePattern: PropTypes.string
  }).isRequired,
  onValidPassword: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  minLength: PropTypes.number,
  submit: PropTypes.func
};

LoginPassword.defaultProps = {
  autoFocus: false,
  className: 'mb-3',
  minLength: 8,
  submit: undefined
};

export default LoginPassword;
