import React from 'react';
import PropTypes from 'prop-types';

import Notification from 'learn-common-notification';

import redirect from '../../shared/utils/redirect';
import { verifyVerificationCode, requestVerificationCode, provideExistingEmailaddress, provideNewEmailaddress  }
  from '../../shared/services/verificationcode';

import InformationRequiredForm from './components/InformationRequiredForm';

export class InformationRequiredPage extends React.Component {
  state = {
    continuationCode: '',
    emailAddress: '',
    eventState: '',
    infoMessage: '',
    isLoading: false,
    isValidEmailAddress: false,
    isUnknownEmailAddress: false,
    isEmailAddressValidated: false,
    isValidVerificationCode: false,
    isVerificationCodeValidated: false,
    showVerificationCodeInput: false,
    validatedEmailAddresses: [],
    validatedVerificationCodes: []
  };

  setEmailAddressFromInitialState = username => ({
    ...this.state,
    emailAddress: username,
    isValidEmailAddress: true,
    isUnknownEmailAddress: false
  });

  setInfoMessage = (key, interpolation) => {
    const { t } = this.props;

    this.setState({
      infoMessage: t(`information-required.messages.${key}`, interpolation),
      eventState: ''
    });
  }

  setEventState = (key, interpolation) => {
    const { t } = this.props;

    this.setState({
      eventState: t(`information-required.messages.${key}`, interpolation),
      infoMessage: ''
    });
  }

  handleRequestVerificationCode = (emailAddressForVerificationCode) => {
    const { metrics } = this.props;

    this.setState({ isLoading: true });
    return requestVerificationCode(emailAddressForVerificationCode)
      .then(() => {
        metrics.registerEvent('VALIDATION-email-address-verification-code-was-sent.');
        this.setState({
          isLoading: false,
          showVerificationCodeInput: true
        });
        this.setInfoMessage('mailboxAccessVerificationCodeEmailSent');
      })
      .catch(() => {
        metrics.registerEvent('VALIDATION-could-not-send-email-address-verification-code.');
        this.setState({ isLoading: false });
        this.setEventState('sendVerificationCodeEventFailed');
      });
  }

  validateLoginId = (emailAddressToValidate) => {
    const { emailAddress } = this.state;
    const emailAddressHasChanged = emailAddressToValidate !== emailAddress;
    if (emailAddressHasChanged) {
      this.setState({
        emailAddress: emailAddressToValidate,
        showVerificationCodeInput: false,
        eventState: '',
        infoMessage: '',
        verificationCode: '',
        isValidVerificationCode: false,
        isVerificationCodeValidated: false
      });
    }
    this.setState({ emailAddressChanged: emailAddressHasChanged });
  }

  handleUseNewEmailaddress = () => {
    const { emailAddress } = this.state;
    const { metrics } = this.props;

    metrics.registerEvent('VALIDATION-provide-new-email-address');
    provideNewEmailaddress(emailAddress)
      .then((response) => {
        if (response.Success) {
          metrics.registerEvent('VALIDATION-provided-new-email-address-is-valid');
          this.setState({
            isLoading: false,
            continuationCode: response.RedirectUrl
          });
          redirect(response.RedirectUrl);
        } else {
          metrics.registerEvent('VALIDATION-provided-new-email-address-is-invalid');
          this.setEventState('errorNewEmailaddress');
        }
      });
  }

  validateVerificationCode = (verificationCode) => {
    const { metrics } = this.props;

    metrics.registerEvent('VALIDATION-validate-verification-code');
    this.setState({ isLoading: true });
    verifyVerificationCode(verificationCode)
      .then((continuationCode) => {
        metrics.registerEvent('VALIDATION-verification-code-is-valid');
        this.setState({
          isLoading: false,
          eventState: '',
          showVerificationCodeInput: false,
          isUnknownVerificationCode: false,
          isVerificationCodeValidated: true,
          continuationCode
        });
      })
      .catch((error) => {
        if (error.message !== 'Too many attempts') {
          const plural = error.message > 1;
          metrics.registerEvent(`VALIDATION-verification-code-is-invalid-${error.message}-attempt${plural ? 's' : ''}-remaining`);
          this.setEventState(`eventWrongVerificationCode${plural ? '_plural' : ''}`, { amount: error.message });
        } else {
          metrics.registerEvent('VALIDATION-verification-code-is-invalid-too-many-attempts');
          this.setEventState('eventWrongVerificationCodeResendVerificationCodeEvent');
        }
        this.setState({
          infoMessage: '',
          isLoading: false,
          isUnknownVerificationCode: true,
          isValidVerificationCode: false,
          isVerificationCodeValidated: true,
          showVerificationCodeInput: true
        });
      });
  }

  handleContinueClick = () => {
    const { emailAddress, isValidEmailAddress } = this.state;
    if (!isValidEmailAddress) return;
    provideNewEmailaddress(emailAddress)
      .then((response) => {
        if (response.IsSuccess) {
          this.setState({
            isLoading: false
          });
          redirect(response.RedirectUrl);
        } else if (response.Reason === 'A code is needed for an existing emailaddress') {
          this.handleRequestVerificationCode(emailAddress);
        } else {
          this.setEventState('verifyEmailEventFailed');
        }
      });
  };

  handleContinueToClient = () => {
    const { continuationCode } = this.state;
    provideExistingEmailaddress(continuationCode)
      .catch(() => this.setEventState('continuationCodeError'));
  }

  handleVerifyVerificationCode = () => { }

  handleEmailAddressValidation = ({ isValid, emailAddress }) => {
    if (isValid) {
      this.setState({ isValidEmailAddress: true });
      this.validateLoginId(emailAddress);
    } else {
      this.setState({
        isEmailAddressValidated: false,
        isValidEmailAddress: false,
        emailAddress,
        showVerificationCodeInput: false,
        infoMessage: '',
        verificationCode: '',
        isValidVerificationCode: false,
        isVerificationCodeValidated: false
      });
    }
  };

  handleVerificationCodeValidation = ({ isValid, verificationCode }) => {
    if (isValid) {
      this.setState({ isValidVerificationCode: true });
      this.validateVerificationCode(verificationCode);
    } else {
      this.setState({
        isValidVerificationCode: false
      });
    }
  };

  handleSubmit = () => {
    const { continuationCode } = this.state;

    provideExistingEmailaddress(continuationCode);
  };

  render() {
    const {
      continuationCode,
      emailAddress,
      eventState,
      infoMessage,
      isEmailAddressValidated,
      isLoading,
      showVerificationCodeInput,
      isVerificationCodeValidated,
      isValidVerificationCode
    } = this.state;

    return (
      <React.Fragment>
        {eventState
          && (
            <Notification error message={eventState} title="" />
          )
        }

        {infoMessage
          && (
            <Notification info message={infoMessage} title="" />
          )
        }

        <InformationRequiredForm
          {...this.props}
          continuationCode={continuationCode}
          emailAddress={emailAddress}
          isEmailAddressValidated={isEmailAddressValidated}
          isLoading={isLoading}
          isValidVerificationCode={isValidVerificationCode}
          isVerificationCodeValidated={isVerificationCodeValidated}
          showVerificationCodeInput={showVerificationCodeInput}
          onContinueClick={this.handleContinueClick}
          onContinueToClient={this.handleContinueToClient}
          onEmailAddressValidation={this.handleEmailAddressValidation}
          onSubmit={this.handleSubmit}
          onVerificationCodeValidation={this.handleVerificationCodeValidation}
          onVerifyVerificationCode={this.handleVerifyVerificationCode}
        />
      </React.Fragment>
    );
  }
}

InformationRequiredPage.propTypes = {
  continuationUrl: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  // loginUrl: PropTypes.string.isRequired,
  // signInId: PropTypes.string.isRequired,
  // antiForgery: PropTypes.shape({
  //   name: PropTypes.string.isRequired,
  //   value: PropTypes.string.isRequired
  // }),
  errorMessage: PropTypes.string
};

InformationRequiredPage.defaultProps = {
  // antiForgery: undefined,
  errorMessage: ''
};

export default InformationRequiredPage;
