import React from 'react';
import PropTypes from 'prop-types';

import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';
import translate from 'learn-common-i18n';

export const NotAuthenticated = (props) => {
  const { redirectUrl, t } = props;

  return (
    <React.Fragment>
      <h2>
        {t('logout.headers.noActiveSession')}
      </h2>

      <Divider primary thick className="mb-3" />

      <Notification info message={t('logout.messages.noActiveSession')} title="" />

      {redirectUrl && (
        <React.Fragment>
          <Divider gray />
          <div className="text-center">
            <a data-purpose="redirect-link" href={redirectUrl}>
              {t('logout.actions.backToSite')}
            </a>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>);
};

NotAuthenticated.propTypes = {
  redirectUrl: PropTypes.string
};

NotAuthenticated.defaultProps = {
  redirectUrl: ''
};

export default translate()(NotAuthenticated);
