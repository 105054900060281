import React from 'react';
import PropTypes from 'prop-types';

import Button from 'learn-common-button';
import translate from 'learn-common-i18n';

import PasswordStrength from '../../../components/PasswordStrengthLoadable';

import LoginPassword from '../../login/components/LoginPassword';

export class ResetLandingForm extends React.Component {
  state = {
    password: ''
  };

  handlePasswordValidation = ({ isValid, password }) => {
    const { onPasswordValidation } = this.props;
    this.setState({ password });
    onPasswordValidation({ isValid, password });
  }

  render() {
    const { password } = this.state;
    const { isLoading, onSubmit, t } = this.props;

    return (
      <form noValidate id="reset-landing">
        <LoginPassword
          className=""
          label={t('reset.fields.password.label')}
          messages={{
            validatePattern: t('reset.fields.password.validatePattern'),
            validateRequired: t('reset.fields.password.validateRequired')
          }}
          minLength={8}
          submit={onSubmit}
          onValidPassword={this.handlePasswordValidation}
        />

        <PasswordStrength
          minLength={8}
          password={password}
          texts={{
            weak: t('password.texts.weak'),
            strong: t('password.texts.strong'),
            suggestion: t('password.messages.suggestion')
          }}
          userInputs={[]}
        />

        <Button
          fullWidth
          secondary
          loading={isLoading}
          text={t('reset.actions.savePassword')}
          onClick={onSubmit}
        />
      </form>
    );
  }
}

ResetLandingForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onPasswordValidation: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default translate()(ResetLandingForm);
