import Loadable from 'react-loadable';

const PasswordStrengthLoadable = Loadable({
  loader: () => import('learn-common-passwordstrength'),
  loading: () => null
});

PasswordStrengthLoadable.displayName = 'PasswordStrengthLoadable';

export default PasswordStrengthLoadable;
