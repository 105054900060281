import React from 'react';
import { validateEmail } from './validation';

export const getErrorsWithMarkup = errors => Object.keys(errors).reduce((result, key) => {
  const value = errors[key];
  return {
    ...result,
    [key]: value
      ? (
        <div className="text-danger messages mt-1" data-purpose={`form-error ${key}-error`}>
          {value}
        </div>
      )
      : undefined
  };
}, {});

export function handleFormChange(target) {
  const {
    id, type, checked, value
  } = target;
  const newValue = type === 'checkbox' ? checked : value;
  return ({
    [id]: newValue
  });
}

export function validateFormField(target, state) {
  const { checked, id, minLength, maxLength, type, value = '' } = target;
  const valueToCheck = value.trim();
  let newValue = type === 'checkbox' ? checked : value;
  const validationRules = target.dataSet || getDataSetFromAttributes(target.attributes);
  let error;

  // check required
  if (validationRules.validateRequired) {
    if (valueToCheck === '') {
      error = validationRules.validateRequired;
    }
  }

  // check email
  if (!error && valueToCheck && validationRules.validateEmail) {
    if (!validateEmail(valueToCheck)) {
      error = validationRules.validateEmail;
    }
  }

  // min length
  if (!error && valueToCheck && validationRules.validateMinlength) {
    if (valueToCheck.length < minLength) {
      error = validationRules.validateMinLength;
    }
  }

  // max length
  if (!error && valueToCheck && validationRules.validateMaxlength) {
    if (valueToCheck.length > maxLength) {
      error = validationRules.validateMaxLength;
    }
  }

  // pattern
  if (!error && valueToCheck && validationRules.validatePattern) {
    if (target.validity.patternMismatch === true) {
      error = validationRules.validatePattern;
    }
  }

  // No empty values
  if (newValue.trim() === '') {
    newValue = '';
  }

  return {
    [id]: newValue,
    errors: {
      ...state.errors,
      ...getErrorsWithMarkup({ [id]: error })
    }
  };
}

export function getDataSetFromAttributes(attributes) {
  if (!attributes || attributes.length === 0) return {};
  return []
    .filter.call(attributes, at => /^data-/i.test(at.name))
    .reduce((dataSet, { name, value }) => {
      const key = name
        .toLowerCase()
        .split('-')
        .map((namePart, index) => {
          if (index === 0) return '';
          if (index === 1) return namePart;
          return namePart.replace(/^(.)/g, (match, chr) => chr.toUpperCase());
        })
        .join('');
      dataSet[key] = value; // eslint-disable-line no-param-reassign
      return dataSet;
    }, {});
}

export function handleChange(e) {
  const { target } = e;
  this.setState(prevState => ({
    ...handleFormChange(target, prevState)
  }));
}

export function validateField(e) {
  const { target } = e;
  this.setState(prevState => ({
    ...validateFormField(target, prevState)
  }));
}
