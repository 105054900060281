import React from 'react';
import PropTypes from 'prop-types';

const ConsentScope = ({ description, displayName, emphasize, name, required, selected, mandatoryText }) => (
  <div className="form-check">
    <label className="form-check-label">
      <input
        className="form-check-input"
        defaultChecked={selected}
        id={`scopes_${name}`}
        name="scopes"
        readOnly={required}
        type="checkbox"
        value={name}
      />
      <span className={emphasize === true ? 'font-weight-bold' : ''}>
        {displayName || name}
      </span>
      {required
        && (
        <span>
          {' '}
          {mandatoryText}
        </span>
        )
      }
      {description
        && (
        <span>
          <br />
          {description}
        </span>
        )
      }
    </label>
  </div>
);

ConsentScope.propTypes = {
  mandatoryText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  displayName: PropTypes.string,
  emphasize: PropTypes.bool,
  required: PropTypes.bool,
  selected: PropTypes.bool
};

ConsentScope.defaultProps = {
  selected: true,
  required: true,
  emphasize: false,
  displayName: '',
  description: ''
};

export default ConsentScope;
