import React from 'react';

import Divider from 'learn-common-divider';
import Notification from 'learn-common-notification';

export const Error404 = () => (
  <React.Fragment>
    <h2>
      {'Pagina niet gevonden'}
    </h2>

    <Divider primary thick className="mb-3" />

    <Notification error message="De opgevraagde pagina kon niet worden gevonden." />

    <Divider gray className="mt-3" />

    <div className="text-center mb-2">
      <a href="/core/login/redirect">
        {'Ga naar de inlogpagina'}
      </a>
    </div>
  </React.Fragment>
);

export default Error404;
